.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

img{
  margin: auto;
}

.carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  color: black;
}

p, li, td, th, .regular{
  font-size: 1.2em;
}

h2{
  font-weight: bold;
  margin-bottom: 50px;
}

h3{
  font-weight: bold;
}